.category {
    h1 {
        font-size: var(--font-7);
        padding: 0;

        @media (min-width: 900px) {
            padding: .5em;
        }
        
    }
}

.brand-hdr {
    h1 {
        background-position-y: 0;
    }

    .brand-country {
        display: flex;
        justify-content: center;
        padding: 5px;
        border-radius: 2px;
        font-size: var(--font--1);
        font-weight: 300;
        /* background: var(--cl-bg-pink-light); */
    
        img {
            margin-right: 5px;
        }
    }
}

.c-more-products {
    margin: var(--gap-double) 0;
    padding-top: var(--gap);
    border-top: 6px solid var(--cl-border-red);
}