.c-product {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-width: var(--product-card-min-width);
    max-width: calc(
        (100% - ((var(--items-in-row) - 1) * var(--gap))) / var(--items-in-row)
    );
    margin-bottom: var(--gap-double);
}

.c-product__image {
    position: relative;
    margin-bottom: var(--gap);

    img {
        display: block;
        width: 100%;
        border: 1.5px solid var(--cl-border-x-light);
        border-radius: 5px;
        transition: var(--transition-base);

        &.dark {
            border-color: transparent; 
        }

        &:hover {
            border-color: var(--cl-border-light);
        }

        .c-product-promo & {
            border-width: 2px;
            border-color: var(--cl-border-dark);
        }

        .out-of-stock & {
            opacity: .4;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.c-product__comments {
    position: absolute;
    bottom: 4px;
    left: 4px;
    padding: 0 4px 0 2px;
    font-size: var(--font--2);
    font-weight: 500;
    border-radius: 3px;
    background-color: #fff;

    a {
        border-bottom: none;
        color: var(--cl-text-base);

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin: 0 3px 1px 0;
            width: 18px;
            height: 18px;
            opacity: .6;
            background-image: url("data:image/svg+xml,%3Csvg width='18' xmlns='http://www.w3.org/2000/svg' height='18' fill='none'%3E%3Cg style='fill: rgb(0, 0, 0);'%3E%3Cpath d='M1.5 4.5A1.5 1.5 0 0 1 3 3h12a1.5 1.5 0 0 1 1.5 1.5v8.25a1.5 1.5 0 0 1-1.5 1.5h-3.439L9.53 16.28a.749.749 0 0 1-1.06 0l-2.031-2.03H3a1.5 1.5 0 0 1-1.5-1.5V4.5Zm13.5 0H3v8.25h3.75c.199 0 .39.079.53.22L9 14.689l1.72-1.719a.747.747 0 0 1 .53-.22H15V4.5Z' fill-rule='evenodd' clip-rule='evenodd' style='fill: rgb(40, 40, 40);' class='fills'/%3E%3Cpath d='M10.125 8.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM13.125 8.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM7.125 8.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z' style='fill: rgb(40, 40, 40);' class='fills'/%3E%3C/g%3E%3C/svg%3E");
            transition: var(--transition-base);
        }    
    }

    &:hover {
        a {
            color: var(--cl-text-carmine-dark);

            &:before {
                opacity: 1;
            }
        }
    }
}

.c-product__details {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.c-product__info {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: var(--gap);
}

.c-product__title {
    margin-bottom: var(--gap);
    font-size: var(--font-2);
    font-weight: 400;

    a:has(.c-product__title-ext),
    a:has(+.c-product__title__option) {
        margin-right: .25em;
    }

    .out-of-stock & a {
        color: var(--cl-text-light);
    }
}

.c-product__title-ext {
    white-space: nowrap;
    font-size: var(--font--2);
}

.c-product__title__option {
    display: inline-block;
    white-space: nowrap;
    padding: 1px 4px;
    font-size: var(--font--2);

    border: 1px solid var(--cl-button-red);
    border-radius: 3px;
    color: var(--cl-text-carmine-dark);
}

.c-product__summary {
    flex: 1 1 100%;
    position: relative;
    font-size: var(--font--1);

    &:has(+ .c-product__options) {
        margin-bottom: var(--gap);
    }

    .service-clone {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        padding: 7px 10px;
        border-color: transparent;
        border-radius: 4px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.14);
        background: #fff;
    }    
}

.c-product__code {
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 1px 3px;
    font-size: 9px;
    border-radius: 3px;
    color: white;
    background: black;
    opacity: .3;
}

.c-product:has(img.dark) .c-product__code {
    opacity: .8;
}

.c-product__options {
    select {
        width: 100%;
        padding: 4px 5px;
        font-size: var(--font--2);
        border-radius: 3px;

        &.is-awaiting-choose {
            background-color: #ffffe0;
        }
    }

    .item {
        display: inline-block;
        min-width: 32px;
        margin-right: 6px;
        padding: 4px 6px;
        border: 1.5px solid var(--cl-border-light);
        border-radius: 3px;
        font-size: var(--font--2);
        outline: 0;
        color: var(--cl-text-base);
        background-color: #fff;
        cursor: pointer;

        @media (--x-small)  {
            min-width: 26px;
            padding: 3px 4px;
        }

        &.item--active {
            font-weight: bold;
            border-color: var(--cl-button-green);
            color: white;
            background-color: var(--cl-button-green);
        }

        &.item--disabled {
            position: relative;
            opacity: .6;
            border-style: dashed;
            color: var(--cl-text-light);
            cursor: default;

            &:before {
                position: absolute;
                content: '';
                left: -2px;
                top: 50%;
                right: -2px;
                border-top: 1px solid;
                border-color: var(--cl-border-base);
                transform: rotate(35deg);
                color: var(--cl-text-base);                
            }
        }
    }
}

.c-product__shop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--gap);
    border-top: 1.5px solid var(--cl-border-x-light);
}

.c-product__price {
    flex: 1 1 100%;
    line-height: .9;
}

.c-product__discount {
    position:absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    line-height: 25px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 50%;
    text-align: center;
    color: white;
    background: black;
}

@keyframes barberpole {
    100% {
        background-position: 100% 100%;
    }
}

.c-product__cart-btn,
.c-product__request-btn,
.c-product__cart-link {
    align-self: flex-end;

    .btn, a {
        white-space: nowrap;
        padding: 6px 12px;
        border: 2px solid var(--cl-button-green);
        border-radius: 4px;
        font-size: var(--font--1);
        font-weight: 600;
        letter-spacing: -.25px;
        color: var(--cl-button-green);
        background: #fff;
        cursor: pointer;

        @media (min-width: 390px) {
            padding: 7px 14px;
            font-size: var(--font--2);
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                color: white;
                background-color: var(--cl-button-green);
                transition: var(--transition-base);
            }
        }
    }

    .btn--frozen {
        border-color: var(--cl-button-frozen);
        color: var(--cl-button-frozen);

        &:hover {
            background-color: #fff;
        }
    }

    .btn--in-process {
        opacity: .5;
        background-image:
            repeating-linear-gradient(
                -45deg,
                transparent,
                transparent 10px,
                #ccc 10px,
                #ccc 20px
            );
        background-size: 400% 400%;
        animation: barberpole 5s linear infinite;
    }

    .btn--processed {
        padding-left: 4px;
        padding-right: 4px;
        background-color: var(--cl-button-red);
        border-color: var(--cl-button-red);
        color: #fff;
    }
}

.c-product__request-btn {
    width: 100%;

    .btn {
        width: 100%;
        border-color: var(--cl-button-red);
        color: var(--cl-button-red);

        &:hover {
            background-color: var(--cl-button-red);
        }
    }
}

.c-product__cart-link {
    width: 100%;
    text-align: center;

    a {
        display: inline-block;
        width: 100%;
        border-color: #2962ff;
        color: #2962ff;

        &:after {
            content: '➜';
            margin-left: 6px;
        }

        &:hover {
            background: #2962ff;
            border-color: #2962ff;
        }
    }        
}

.c-product__badge {
    position: absolute;
    top: 46%;
    left: 0;
    width: 100%;
    padding: .1rem;
    font-size: var(--font--4);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: yellow;
    background: black;
}

.badge-priceoff {
    color: white;
    background: var(--cl-bg-red);
}
