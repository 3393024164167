.search__pre {
    margin-bottom: 1.5em;
    font-size: var(--font-0);
}

.search__query {
    font-weight: bold;
    color: var(--cl-text-green);

    &:before {
        content: '\7b';
        margin: 0 .25rem;
    }

    &:after {
        content: '\7d';
        margin: 0 .25rem;
    }

}