@custom-media --small-phone (max-width: 320px);
@custom-media --phone (min-width: 360px);
@custom-media --fablet (min-width: 760px);

:root {
    --page-min-width: 320px;

    --gap: 12px;
    --gap-double: calc(var(--gap) * 2);
    --gap-half: calc(var(--gap) / 2);
    --gap-fifth: calc(var(--gap) / 5);
    --gap-triple: calc(var(--gap) * 3);
    --gap-minus: calc(-1 * var(--gap));

    --product-card-min-width: 160px;
    --items-in-row: 2;

    --cl-bg-pink-light: #fce3e7;
    --cl-bg-red: #d8000c;

    --cl-border-dark: #666;
    --cl-border-base: #ccc;
    --cl-border-light: #d6d6d6;
    --cl-border-x-light: #f0f0f0;
    --cl-border-red: #ee6e73;

    --cl-button-green: #1abc9c; 
    --cl-button-red: #ee6e73;
    --cl-button-frozen: #767676;

    --cl-text-base: #666;
    --cl-text-dark: #333;
    --cl-text-light: #767676;
    --cl-text-x-light: #ccc;
    --cl-text-carmine: #ee6e73;
    --cl-text-carmine-dark: #e6242c;
    --cl-text-red: #f90009;
    --cl-text-green: #7bae37;

    --font-size-base: 13px;

    --font--5: calc(var(--font-size-base) - 5px);
    --font--4: calc(var(--font-size-base) - 4px);
    --font--3: calc(var(--font-size-base) - 3px);
    --font--2: calc(var(--font-size-base) - 2px);
    --font--1: calc(var(--font-size-base) - 1px);
    --font-0: var(--font-size-base);
    --font-1: calc(var(--font-size-base) + 1px);
    --font-2: calc(var(--font-size-base) + 2px);
    --font-3: calc(var(--font-size-base) + 3px);
    --font-4: calc(var(--font-size-base) + 4px);
    --font-5: calc(var(--font-size-base) + 5px);
    --font-6: calc(var(--font-size-base) + 6px);
    --font-7: calc(var(--font-size-base) + 7px);

    --transition-base: opacity 0.2s linear, background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
}

@media (max-width: 320px) {
    :root {
        --font-size-base: 12px;
        --gap: 10px;
        --product-card-min-width: 145px;
    }
}

@media (min-width: 390px) {
    :root {
        --font-size-base: 14px;
        --gap: 14px;
    }
}

@media (min-width: 540px) {
    :root {
        --items-in-row: 3;
    }
}

@media (min-width: 710px) {
    :root {
        --items-in-row: 4;
    }
}

@media (min-width: 900px) {
    :root {
        --items-in-row: 5;
    }
}

/* @media (min-width: 1060px) {
    :root {
        --items-in-row: 6;
    }
} */
