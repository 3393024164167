.product-rating {
    padding: var(--gap-double);
    gap: var(--gap-double);

    .c-product {
        flex: 0 0 calc(50% - var(--gap));
        max-width: none;
        min-width: 300px;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;

        &:after {
            content: ' ';
            position: absolute;
            width: 30%;
            height: 6px;
            left: 35%;
            bottom: 0;
            margin-bottom: calc(var(--gap-minus) - 3px);
            background-color: var(--cl-text-carmine);
        }
    }

    .c-product__image {
        flex: 0 0 50%;
        padding-right: var(--gap-half);
    }

    .c-product__badge {
        width: calc(100% - var(--gap-half));
    }

    .c-product__details {
        flex: 0 0 50%;
        display: block;
        padding-left: var(--gap-half);
        background-repeat: no-repeat;
        background-size: auto calc(4 * var(--gap));
        background-position: calc(100% - var(--gap)) calc(100% + var(--gap));
        background-image: none !important;
    }

    .c-product__discount {
        left: calc(50% - 24px - var(--gap));
    }

    .c-product__why {
        order: 1;
        padding: var(--gap-double);
        border-radius: var(--gap);
        font-size: var(--font-1);
        background-image: none, linear-gradient(#f2f2f2 0, #fff 50%);
        background-repeat: no-repeat, no-repeat;
        background-size: auto 110px, contain;
        background-position: 104% -4px, 0 0;
    }

    .rating-10 .c-product__why {
        background-image: url(/static/i/w/rating_10.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-9 .c-product__why {
        background-image: url(/static/i/w/rating_9.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-8 .c-product__why {
        background-image: url(/static/i/w/rating_8.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-7 .c-product__why {
        background-image: url(/static/i/w/rating_7.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-6 .c-product__why {
        background-image: url(/static/i/w/rating_6.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-5 .c-product__why {
        background-image: url(/static/i/w/rating_5.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-4 .c-product__why {
        background-image: url(/static/i/w/rating_4.png), linear-gradient(#ececec 0, #fff 50%);
    }

    .rating-3 .c-product__why {
        background-image: url(/static/i/w/rating_3.png), linear-gradient(#f0e3e3 0, #fff 50%);
    }

    .rating-2 .c-product__why {
        background-image: url(/static/i/w/rating_2.png), linear-gradient(#e3e6e9 0, #fff 50%);
    }

    .rating-1 .c-product__why {
        background-image: url(/static/i/w/rating_1.png), linear-gradient(#f9f0cc 0, #fff 50%);
    }

    .c-product__why__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-block-end: 1em;
        margin-right: calc(-1 * var(--gap-double));      
        font-size: var(--font-6);
        font-weight: 300;
        line-height: 1.25;

        .service-prefix {
            display: block;
            font-size: var(--font-1);
        }

        a {
            margin-left: var(--gap-double);
            margin-right: 4px;
            border-bottom: 1.5px solid #000;
            font-weight: 500;
            color: #000;
        }
    }
    
    @media (max-width: 460px) {
        margin: 0 var(--gap-minus);
        padding: var(--gap);

        .c-product {
            flex: 1 1 100%;
            max-width: none;
        }    

        .c-product__details {
            background-size: auto calc(8 * var(--gap));
        }

        .rating-10,
        .rating-8,
        .rating-6,
        .rating-4,
        .rating-2 {
            .c-product__image {
                padding-right: var(--gap-half);
            }

            .c-product__details {
                margin-right: var(--gap-minus);
                padding-left: 0;
                padding-right: var(--gap);
                background-position: calc(100% + 6px) calc(100% + var(--gap));
            }

            .c-product__info,
            .c-product__shop {
                margin-left: calc(-1 * var(--gap-half));
            }
        
            .c-product__why {
                margin-right: var(--gap-minus);
                border-radius: var(--gap) 0 0 var(--gap);
            }
        }

        .rating-9,
        .rating-7,
        .rating-5,
        .rating-3,
        .rating-1 {
            flex-direction: row-reverse;

            .c-product__image {
                padding-right: 0;
                padding-left: var(--gap-half);
            }

            .c-product__badge {
                left: var(--gap-half);
            }

            .c-product__comments {
                left: calc(4px + var(--gap-half));
            }

            .c-product__details {
                margin-left: var(--gap-minus);
                padding-left: var(--gap);
                background-position: -6px calc(100% + var(--gap));
            }

            .c-product__info {
                text-align: right;
            }

            .c-product__info,
            .c-product__shop {
                margin-right: calc(-1 * var(--gap-half));
            }

            .c-product__discount {
                left: auto;
                right: 5px;
            }
                    
            .c-product__code {
                left: calc(50% + var(--gap-half) + 3px);
            }

            .c-product__why {
                margin-left: var(--gap-minus);
                padding-left: var(--gap-triple);
                border-radius: 0 var(--gap) var(--gap) 0;
                background-position: -4% -4px, 0 0;
            }

            .c-product__why__header {
                margin-left: calc(-1 * var(--gap-triple));
                padding-left: calc(var(--gap) * 8);
                padding-right: var(--gap);
            }
        }
    }

    @media (--fablet) {
        padding: 0;
    }
}
