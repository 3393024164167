/* == PRICES == */

.price {
    text-align: center;
    font-size: var(--font-4);
    color: black;

    .sale-price & {
        color: var(--cl-text-red);
    }

    &:after {
        content: '₽';
        font-size: var(--font-0);

        @media (--x-small) {
            margin-left: 2px;
        }

        @media (--small-plus) {
            margin-left: 4px;
        }
    }
}

.price__from {
    margin-right: .5em;
    font-size: var(--font--2);
}

.sale-price {
    display: flex;
    flex-direction: column;
}

.base-price {
    align-self: flex-start;
    position: relative;
    font-size: var(--font-0);
    color: var(--cl-text-x-light);

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 45%;
        width: 100%;
        border-top: 1.5px solid var(--cl-border-light);
    }
}

/* == VARIOUS == */

.text-content {
    line-height: 1.5;
}