.product-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    /* padding: var(--gap); */
}

.product-list__submenu {
    font-size: var(--font-0);
    ul {
        padding: 0;
        list-style-type: none;

        @media (--fablet) {
            text-align: center;
        }
    }

    li {
        margin: .3em 0;
        &:before {
            content: '\2022';
            margin-right: var(--gap);
        }

        @media (--fablet) {
            display: inline-block;
            margin: 0;

            &:before {
                content: none;
            }

            &:after {
                content: '\2022';
                margin: 0 1em;
            }

            &:last-of-type:after {
                content: none;
            }
        }        
    }
}

.product-list__description {
    /* отключаем пэдингом схлопывание отступов и сдвигаем на этот отступ вверх */
    margin: -1em 0 2rem;
    padding: .01em 0;
    font-size: var(--font-0);
    line-height: 1.4;
  
    .no-header & {
        margin-top: 0;
    }
}

.is-folded {
    position: relative;
    overflow: hidden;
  
    &:before {
        content: '';
        width: 100%;
        height: 110%;
        position: absolute;
        z-index:1;
        left: 0;
        top: 0;
        /* border-bottom: 1.5rem solid white; */
        background: linear-gradient(to top,
            rgba(255,255,255, 1) 10%,
            rgba(255,255,255, 0) 80%
        );
        pointer-events: none;
    }
  
    .unfold-description {
        position: absolute;
        z-index: 2;
        bottom: var(--gap);
        width: 100%;
        text-align: center;
    
        button {
            display: inline-block;
            cursor: pointer;
            padding: 2px 6px;
            font-size: var(--font--1);
            border: 1.5px solid currentColor;
            border-radius: 3px;
            color: var(--cl-text-carmine-dark);
            background-color: #fff;

            &:hover {
                color: #fff;
                border-color: var(--cl-text-carmine-dark);
                background-color: var(--cl-text-carmine-dark);
            }
        }
    }
}
