.badge-priceoff {
    color: white;
    background: var(--cl-bg-red);
}

.c-priceoff__desc {
    padding: .25rem .5rem;
    border-radius: 4px;
    font-size: var(--font--2);
    color: var(--cl-text-red);
    background-color: var(--cl-bg-pink-light);
}
